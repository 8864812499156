import React, { Fragment } from 'react';
import { View, Platform, FlatList } from 'react-native';
import PropTypes from 'prop-types';
import {
  BannerSlider,
  ProductsList,
  HeaderSeeAll,
  ProductsSlider,
  CoverScrollList,
  CategoryGrid,
  BannerCard,
  BannerCover,
  MiniBoxSlider,
  ShimmerHome,
  MiniItemList,
} from '../index';
import Animated from 'react-native-reanimated';
import { onScrollEvent } from 'react-native-redash';
import styles from './styles';
import { COMPONENT_TYPES } from '../../common/homeComponentsTypes';
import { useScrollToTop } from '@react-navigation/native';
// import AutoPlayVideo from '../AutoPlayVideo';
const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);
const { Value, interpolate, Extrapolate, diffClamp, multiply } = Animated;
const NAV_BAR_HEIGHT = 48;
class WidgetsComponent extends React.Component {
  static propTypes = {
    widgets: PropTypes.array,
    onPressItem: PropTypes.func,
    onPressSeeAll: PropTypes.func,
    openStoryScreen: PropTypes.func,
    loading: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.scrollY = new Value(0);

    const diffClampNode = diffClamp(this.scrollY, 0, NAV_BAR_HEIGHT);
    this.animatedNavBarTranslateY = multiply(diffClampNode, -1);
    this.animatedTitleOpacity = interpolate(this.animatedNavBarTranslateY, {
      inputRange: [-10, 0],
      outputRange: [0, 1],
      extrapolate: Extrapolate.CLAMP,
    });

    this.opacity = interpolate(this.animatedNavBarTranslateY, {
      inputRange: [-10, 0],
      outputRange: [0, 1],
      extrapolate: Extrapolate.CLAMP,
    });

    this.invOpacity = interpolate(this.animatedNavBarTranslateY, {
      inputRange: [-10, 0],
      outputRange: [1, 0],
      extrapolate: Extrapolate.CLAMP,
    });
  }

  renderWidgets = ({ item }) => {
    // NOTE: All filtering will be done from server side.
    const { items } = item;
    const {
      navigation,
      onPressSeeAll,
      onPressItem,
      openStoryScreen,
    } = this.props;

    // internal call wrap with component_type.
    const _onPressItem = (it) => {
      onPressItem(it, item.component_type);
    };

    switch (item.component_type) {
      case COMPONENT_TYPES.BANNER_SLIDER:
        return (
          <View key={item.id}>
            {items && items.length > 0 && (
              <BannerSlider
                items={items.filter((it) => it.image_url)}
                loop
                autoplay
                paginationContainerStyle={styles.bannerSliderPagination}
                containerStyle={styles.bannerSliderContainer}
                onPressSliderItem={_onPressItem}
              />
            )}
          </View>
        );

      case COMPONENT_TYPES.BANNER_CARD_SLIDER:
        return (
          <View key={item.id}>
            {items && items.length > 0 && (
              <BannerSlider
                items={items.filter((it) => it.image_url)}
                loop
                autoplay
                withDetails
                showPagination={false}
                containerStyle={styles.bannerSliderContainer}
                onPressSliderItem={_onPressItem}
              />
            )}
          </View>
        );

      case COMPONENT_TYPES.MINI_BOX_SLIDER:
        return (
          <MiniBoxSlider
            key={item.id}
            items={items}
            onPress={_onPressItem}
            navigation={navigation}
          />
        );

      case COMPONENT_TYPES.BANNER_HALF:
      case COMPONENT_TYPES.BANNER_COVER:
      case COMPONENT_TYPES.BANNER_TEXT:
        return (
          <BannerCover key={item.id} data={items} onPress={_onPressItem} />
        );

      case COMPONENT_TYPES.BANNER_HALF_DARK:
        return (
          <BannerCover
            key={item.id}
            darkMode={true}
            data={items}
            onPress={_onPressItem}
          />
        );

      case COMPONENT_TYPES.BANNER_CARD:
        return <BannerCard key={item.id} data={items} onPress={_onPressItem} />;

      case COMPONENT_TYPES.BRAND_SLIDER:
        return (
          <MiniItemList key={item.id} data={item} onPress={_onPressItem} />
        );

      case COMPONENT_TYPES.CATEGORY_SLIDER:
        return (
          <CoverScrollList key={item.id} data={item} onPress={_onPressItem} />
        );

      case COMPONENT_TYPES.CATEGORY_GRID_COL_TWO:
        return (
          <Fragment key={item.id}>
            <HeaderSeeAll
              title={item.title}
              hideSeeAll={!item.category_id}
              onPress={() => onPressSeeAll(item)}
            />
            {items && items.length > 0 && (
              <CategoryGrid
                numColumns={2}
                items={items.slice(0, 4)}
                onPress={_onPressItem}
              />
            )}
          </Fragment>
        );

      case COMPONENT_TYPES.CATEGORY_GRID_COL_THREE:
        return (
          <Fragment key={item.id}>
            <HeaderSeeAll
              title={item.title}
              hideSeeAll={!item.category_id}
              onPress={() => onPressSeeAll(item)}
            />
            {items && items.length > 0 && (
              <CategoryGrid
                numColumns={3}
                items={items.slice(0, 6)}
                onPress={_onPressItem}
              />
            )}
          </Fragment>
        );

      case COMPONENT_TYPES.CATEGORY_CARD_COL_TWO:
        return (
          <Fragment key={item.id}>
            <HeaderSeeAll
              title={item.title}
              hideSeeAll={!item.category_id}
              style={styles.seeAllTransparent}
              onPress={() => onPressSeeAll(item)}
            />
            {items && items.length > 0 && (
              <CategoryGrid
                numColumns={2}
                style={styles.categoryCardContentContainer}
                cardStyle={styles.categoryCardChild}
                items={items.slice(0, 4)}
                onPress={_onPressItem}
              />
            )}
          </Fragment>
        );

      case COMPONENT_TYPES.PRODUCT_GRID:
      case COMPONENT_TYPES.PRODUCT_GRID_COL_TWO:
        return (
          <ProductsList
            key={item.id}
            numColumns={2}
            data={items.slice(0, 4)}
            loading={false}
            contentContainerStyle={styles.productsListContainer}
            onPressItem={_onPressItem}
            navigation={navigation}
            ListHeaderComponent={
              <HeaderSeeAll
                title={item.title}
                hideSeeAll={!item.category_id}
                onPress={() => onPressSeeAll(item)}
                style={styles.seeAllTransparent}
              />
            }
          />
        );
      case COMPONENT_TYPES.PRODUCT_SLIDER:
        const storiesData = {};
        return (
          <Fragment key={item.id}>
            <HeaderSeeAll
              title={item.title}
              hideSeeAll={!item.category_id}
              onPress={() => onPressSeeAll(item)}
              style={styles.seeAllTransparent}
            />
            {item.items && item.items.length > 0 && (
              <ProductsSlider
                key={item.id}
                items={items}
                onPressItem={_onPressItem}
                openStoryScreen={openStoryScreen}
                storiesData={storiesData}
                navigation={navigation}
              />
            )}
          </Fragment>
        );

      default:
        return <View key={item.id} />;
    }
    /* eslint-enable semi */
  };

  render() {
    const { loading, widgets, navigation } = this.props;
    if (loading || !widgets.length) return <ShimmerHome loading={loading} />;
    return (
      <View style={styles.container}>
        <AnimatedFlatList
          ref={this.props.scrollRef}
          bounces={false}
          // scrollEnabled={false}
          keyExtractor={(item, index) => index.toString()}
          data={widgets}
          removeClippedSubviews={Platform.OS == 'android'}
          renderItem={this.renderWidgets}
          contentContainerStyle={styles.scrollViewContent}
          onScroll={onScrollEvent({ y: this.scrollY })}
          overScrollMode={'never'}
        />
      </View>
    );
  }
}
const Widgets = (props) => {
  const ref = React.useRef(null);
  useScrollToTop(ref);
  return <WidgetsComponent {...props} scrollRef={ref} />;
};

export default Widgets;
