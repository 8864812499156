import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../redux/order';
import { Spinner, Screen } from '../../components';
import { getURLParams } from '../../common/utils';

import { retrieveCharge as tapOrdersRetriveCharge } from '../../services/tapOrders';
import { navigateTo } from '../../services';

const PaymentHandler = () => {
  const dispatch = useDispatch();
  const paymentMethod = useSelector(
    (state) => state.checkout.selected_payment_method,
  );

  useEffect(() => {
    checkRedirectUrl();
  }, []);

  const handleStatusCharge = (transactionData) => {
    try {
      dispatch(
        actions.handleOrderResult({
          transactionData,
          paymentMethod,
        }),
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  // retrieveCharge function for knet
  const knetRetrieveCharge = async (transactionData) => {
    try {
      handleStatusCharge(transactionData);
    } catch (e) {
      dispatch(actions.restoreOrder());
      setIsRetrieveChargeLoading(false);
      console.log('error in knetRetrieveCharge', e);
    }
  };

  // retrieveCharge function for credit card
  const retrieveCharge = async (params) => {
    console.log('retrieveCharge ');
    try {
      const { tap_id } = params;
      //requesting tap
      const chargeReturnedObject = await tapOrdersRetriveCharge(tap_id);
      console.log('chargeReturnedObject ', chargeReturnedObject);

      const { data, status: statusCode } = chargeReturnedObject;
      if (statusCode === 200 && data) {
        const { status, transaction, response } = data;
        const { code: failedResponseCode } = response;
        const transactionData = {
          result: status,
          transaction,
          failedResponseCode,
        };
        handleStatusCharge(transactionData);
      }
    } catch (e) {
      dispatch(actions.restoreOrder());
      console.log('error in retrieveCharge', e);
      navigateTo();
    }
  };

  const checkRedirectUrl = async () => {
    const url = window.location.href;
    // a function that runs when we reach the url with success or fail
    const params = getURLParams(url);
    const { result } = params;
    console.warn('checkRedirectUrl',params)
    if (paymentMethod.code == 'tap') {
      console.warn('paymentMethod',paymentMethod)
      retrieveCharge(params);
      return false;
    } else if (result) {
      console.warn('result',result)
      knetRetrieveCharge(params);
      return true;
    }
    console.warn('paymentMethod',paymentMethod)
    navigateTo();
    return true;
  };

  return (
    <Screen>
      <Spinner />
    </Screen>
  );
};

// eslint-disable-next-line react/display-name
export default PaymentHandler;
